<template>
  <div class="box">
      <div class="top-box">
          <div>
              <p>Hi，{{myInfo.name}}</p>
              <div class="riqi">
                  <img style="width:18px;height:18px;" src="https://middle-ground.shulanhealth.com/rili-icon%402x.png" alt="">
                  <span>今日 {{toDay}}</span>
              </div>
          </div>
          <img class="head-sty" :src="'https://wx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTL4Mluv3Vq2pwFZVfbeYUelic7xMjAheDCsQcvGVhG3lg6TpRrEZA7fQJzw2FduyWIGnBYFfbJq7nA/132'" alt="">
      </div>
      <div>
            <div class="active-box1">
                <div @click="chsf" style="z-index:999;">
                    <img style="width:36px;height:36px;" src="./comm/ruku-icon.png" alt="">
                    <span>切换身份</span>
                </div>
                <div @click="wdfb" style="z-index:999;">
                    <img style="width:36px;height:36px;" src="./comm/chuku-icon.png" alt="">
                    <span>我的发布</span>
                </div>
            </div>
            <div class="active-box">
                <div style="z-index:999;">
                    <img style="width:36px;height:36px;" src="./comm/search-icon.png" alt="">
                    <span>待开发</span>
                </div>
                <div  style="z-index:999;">
                    <img style="width:36px;height:36px;" src="./comm/chukujilu-icon.png" alt="">
                    <span>待开发</span>
                </div>
                <div  style="z-index:999;position: relative;">
                    <img style="width:36px;height:36px;" src="./comm/jilu-icon.png" alt="">
                    <span>待开发</span>
                    <!-- <van-badge :content="20" class="badges" /> -->
                </div>
            </div>
        </div>

      <!-- <img class="bg1" style="width:100%;" src="https://middle-ground.shulanhealth.com/yqjc-bg%4022x.png" alt=""> -->
      <!-- <img class="bg2" style="width:100%;" src="https://middle-ground.shulanhealth.com/yqjc-bg%4021x.png" alt=""> -->
      <!-- <van-popup v-model="showDepartmentPopup" round position="bottom">
          <div style="width: 100%;position: relative;">
              <div>
                  <input type="text" placeholder="请输入科室或病区名称">
              </div>
              <van-picker
                  title="请选择科室/病区"
                  show-toolbar
                  :columns="columns"
                  @confirm="onsjdConfirm"
                  @cancel="onsjdCancel"
              />
          </div>
      </van-popup> -->
  </div>
</template>
<script>
import {  Toast ,Dialog} from "vant";
import dayjs from 'dayjs'
// import Bus from "@/utils/bus";
import { passenger } from "@/api";
export default {
  components: {
      // VanPicker: Picker,
  },
  data() {
      return {
          isAuth: false,
          showDepartmentPopup: true,
          pmHeight: 0,
          toDay: "",
          columns: [],
          departmentInfo: null,
          userInfo: null,
          myInfo: null,
          types: "",
          car_no: "",
          mobile: "",
          region: "",
          homes: "",
      }
  },
  filters: {
      formatDateTime(date) {
          return date !== undefined && date !== null
              ? date.substring(0, 16)
              : "";
      },
  },
  mounted() {
      document.title = "我的";
      this.myInfo = JSON.parse(localStorage.getItem("user"))
      this.pmHeight =(window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 109;
      this.toDay = dayjs().format('YYYY年MM月DD日')
      // this.getUserinfoAscy()
  },
  methods: {
    wdfb() {
        this.$router.push({
            name: 'wdfb'
        })
    },
    chsf() {
        if(this.myInfo.types == '1') {
            if(this.myInfo.car_no == null || this.myInfo.car_no == '') {
                Dialog.confirm({
                title: '温馨提示',
                confirmButtonText: '去完善',
                confirmButtonColor: '#00B5A0',
                message:
                    '您当前是乘客身份，如需切换到车主身份，您需要先完善车主信息',
                })
                .then(() => {
                    this.$router.push({
                        name: 'wsxx'
                    })
                })
                .catch(() => {
                    // on cancel
                });
            } else {
                Dialog.confirm({
                title: '温馨提示',
                confirmButtonText: '切换',
                confirmButtonColor: '#00B5A0',
                message:
                    '您当前是乘客身份，确定切换到车主身份吗？',
                })
                .then(() => {
                    this.submitSetUserInfo(2)
                })
                .catch(() => {
                    // on cancel
                });
                
            }
        } else {
            Dialog.confirm({
                title: '温馨提示',
                confirmButtonText: '切换',
                confirmButtonColor: '#00B5A0',
                message:
                    '您当前是车主身份，确定切换到乘客身份吗？',
                })
                .then(() => {
                    this.submitSetUserInfo(1)
                })
                .catch(() => {
                    // on cancel
                });
        }
    },
      getUserinfoAscy() {
          let params = {};
          passenger.getMe(params)
              .then((res) => {
                  if(res.data.code == 200) {
                      localStorage.setItem('user', JSON.stringify(res.data.data))
                      this.myInfo = res.data.data
                  } else {
                      Toast.fail('获取信息失败')
                  }
              })
              .catch(() => {
                  Toast.fail('获取信息失败')
              });
      },
      submitSetUserInfo(e) {
          let params = {
              types: e
          };
          passenger.setUserTypes(params)
              .then((res) => {
                  if(res.data.code == 200) {
                      Toast.success('切换成功')
                      this.getUserinfoAscy()
                  } else {
                      Toast.fail('切换失败')
                  }
              })
              .catch(() => {
                  // Toast.fail('切换失败')
              });
          
      },
      handleEvents(e) {
          this.departmentInfo = JSON.parse(e)
      },
      selDepartment() {
          this.$router.push({name: 'search-department'})
      },
      fssqm() {
          this.$router.push({name: 'barcode'})
      },
      phsp() {
          Toast('正在开发中...')
          //this.$router.push({name: 'shengpi'})
      },
      fxrq() {
          Toast('正在开发中...')
          //this.$router.push({name: 'fxry'})
      },
      zybr() {
          this.$router.push({name: 'zybrlb'})
      },
      hlry() {
          this.$router.push({name: 'phrylb'})
      },
  }
};
</script>
<style scoped>
.box {width: 100%;background: linear-gradient(180deg, #00B5A0 0%, #00B5A0 10%,#F2F2F2 25%);min-height: 100vh;position: relative;overflow: hidden;}
.bg1 {position: absolute;top: 0;left: 0;width: 100%;z-index: 1;}
.bg2 {position: absolute;bottom: 0;left: 0;width: 100%;z-index: 1;}
.head-sty {width: 44px;height: 44px;border-radius: 50%;}
.top-box {width: 90%;margin-left: 5%;display: flex;align-items: center;justify-content: space-between;margin-top: 21px;z-index: 2;}
.top-box div p {font-size: 16px;color: #FAFAFA;line-height: 0;}
.riqi {display: flex;align-items: center;}
.riqi img {margin-right: 5px;}
.riqi span {font-size: 13px;color: #FAFAFA;}
.sp-box {width: 92%;margin-left: 4%;height: 88px;background: #FFFFFF;box-shadow: 0px 2px 10px -4px rgba(2,66,58,0.8);border-radius: 8px;margin-top: 24px;display: flex;align-items: center;justify-content: space-between;}
.sp-box1 {width: 92%;margin-left: 4%;background: #FFFFFF;box-shadow: 0px 2px 10px -4px rgba(2,66,58,0.8);border-radius: 8px;margin-top: 24px;padding: 13px 20px;}
.sp-box div {width: 50%;text-align: center;height: 66px;display: flex;flex-direction: column;align-items: center;justify-content: space-around;}
.sp-box div p:nth-child(1) {margin: 0;font-size: 13px;color: #333333;font-weight: 500;}
.sp-box div p:nth-child(2) {margin: 0;font-size: 24px;color: #919295;font-weight: 500;}
.active-box {width: 92%;margin-left: 4%;height: 108px;display: flex;align-items: center;justify-content: space-between;margin-top: 20px;}
.active-box > div {width: 31%;display: flex;flex-direction: column;align-items: center;justify-content: center;height: 108px;background: #FFFFFF;box-shadow: 0px 2px 10px -4px rgba(2,66,58,0.8);border-radius: 8px;}
.active-box div span {margin-top: 14px;font-size: 13px;color: #333333;}
.bqyqjc-box {width: 100%;margin-top:15px;display: flex;
align-items: center;justify-content: space-between;}
.bqyqjc-box > div:nth-child(1) {width: 48%;background: #fff;height: 97px;box-shadow: 0px 2px 10px -4px rgba(2,66,58,0.8);border-radius: 8px;display: flex;flex-direction: column;justify-content: space-between;}
.bqyqjc-box > div:nth-child(2) {width: 48%;background: #fff;height: 97px;box-shadow: 0px 2px 10px -4px rgba(2,66,58,0.8);border-radius: 8px;display: flex;flex-direction: column;justify-content: space-between;}
.arrow {border: 2px solid #919295;
            width: 10px;
            height: 10px;
            border-left-color: transparent;
            border-top-color: transparent;
            transform: rotate( -45deg);}
.qxz {height: 50px;border-radius: 8px;border: 1px solid #E0E0E0;display: flex;align-items: center;justify-content: space-between;padding: 0 15px;margin-top: 20px;z-index: 9;}
.submit-sty {height: 50px;background: #E7E8EB;border-radius: 25px;text-align: center;line-height: 50px;font-size: 16px;color: #fff;margin-top: 21px;position: relative;}
.submit-sty1 {height: 50px;background: #00AC97;border-radius: 25px;text-align: center;line-height: 50px;font-size: 16px;color: #fff;margin-top: 21px;position: relative;}
.badges{position: absolute;top: 10px;right: 10px;}
.qh-box{display: flex;align-items: center;width: 100%;justify-content: space-between;height: 45px;border-bottom: 1px solid #f2f2f2;}
.active-box1 {width: 92%;margin-left: 4%;height: 108px;display: flex;align-items: center;justify-content: space-between;margin-top: 20px;}
.active-box1 > div {width: 48%;display: flex;flex-direction: column;align-items: center;justify-content: center;height: 108px;background: #FFFFFF;box-shadow: 0px 2px 10px -4px rgba(2,66,58,0.8);border-radius: 8px;}
.active-box1 div span {margin-top: 14px;font-size: 13px;color: #333333;}
</style>
